import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import facilityManagementHeader from "../assets/images/facilityManagement_header.jpg";
import facManagement_Breitling from "../assets/images/facManagement_Breitling.jpg";
import facManagement_Redbull from "../assets/images/facManagement_Redbull.jpg";
import facManagement_Chiapparoli from "../assets/images/facManagement_Chiapparoli.jpg";
import facManagement_MD from "../assets/images/facManagement_MD.jpg";
import facManagement_Immobili from "../assets/images/facManagement_Immobili.jpg";
import profilo_Fabio from "../assets/images/Profilo_Fabio.jpg";

const stile = {
  bottone: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    margin: "0 auto",
    display: "table-cell",
    verticalAlign: "middle",
    marginBottom: 20,
    padding: 30,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    textAlign: "center",
    textTransform: "uppercase",
  },
  tabella: {},
  imgCategoria: {
    width: "100%",
  },
  ulCosa: {
    display: "flex",
    justifyContent: "space-around",
  },
};

class FacilityMan extends React.Component {
  render() {
    return (
      <Layout>
        <div id="main">
          <section id="content" className="main">
            <span className="image main">
              <img
                src={facilityManagementHeader}
                alt="Facility Management - Giuvi srl"
              />
            </span>
            <h2>Settore Facility Management</h2>
            <p>
              La manutenzione si può definire come l'insieme di operazioni
              tecnico-gestionali necessarie e utili a mantenere nel tempo
              l'efficienza funzionale e le prestazioni nominali di un impianto
              nel rispetto delle norme di sicurezza. <br />
              <br />
              Non è una condizione sufficiente aver progettato e costruito un
              impianto a regola d'arte, poiché qualsiasi componente, anche se
              impiegato correttamente, non può mantentere invariate nel tempo le
              proprie prestazioni e caratteristiche di sicurezza. Al fine quindi
              di preservare nel tempo gli impianti in conformità alla regola
              dell'arte è indispensabile, anzi fondamentale, una regolare e
              costante attività di manutenzione da effettuarsi con periodicità
              indicate da leggi o norme tecniche del settore. <br />
              <br />
              Le principali finalità della manutenzione sono il mantenimento
              delle prestazioni e del livello di sicurezza iniziale contenendo
              il normale degrado dell'impianto.
            </p>
          </section>
          <section className="main special">
            <header className="major">
              <h2>PRINCIPALI COMMESSE</h2>
            </header>
            <ul className="features" style={stile.ulCosa}>
              <li>
                <span className="image">
                  <img
                    src={facManagement_MD}
                    alt=""
                    style={stile.imgCategoria}
                  />
                  MD DISCOUNT
                </span>
              </li>
              <li>
                <span className="image">
                  <img
                    src={facManagement_Chiapparoli}
                    alt=""
                    style={stile.imgCategoria}
                  />
                  SILVANO CHIAPPAROLI LOGISTICA
                </span>
              </li>
              <li>
                <span className="image">
                  <img
                    src={facManagement_Immobili}
                    alt=""
                    style={stile.imgCategoria}
                  />
                  IMMOBILI DI PREGIO
                </span>
              </li>
            </ul>
          </section>
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>FIGURA DI RIFERIMENTO</h2>
                </header>
                <h3>Fabio Vergani</h3>
                <p>
                  Arrivo da un’esperienza decennale nella manutenzione
                  ordinaria, correttiva, straordinaria e nella gestione
                  cantieristica nei settori meccanico ed edile. Il mio
                  obbiettivo si focalizza sul fornire soluzioni mirate e
                  risolutive alle richieste del cliente. Inseguo da sempre la
                  filosofia del lavoro di squadra, perché credo che solo un Team
                  sia in grado di raggiungere i massimi risultati e mi impegno
                  ad infondere questo ideale lavorativo ad ogni collaboratore.
                  Una squadra non conosce ostacoli, ma solo la meta!
                </p>
                <ul className="actions">
                  <li>
                    <a href="tel:+393498686589">
                      <span className="icon style3 fa-phone"></span> (+39) 349
                      86 86 589
                    </a>
                  </li>
                  <li>
                    <a href="mailto:fabio.vergani@giuvi.it">
                      <span className="icon style3 fa-envelope"></span>{" "}
                      fabio.vergani@giuvi.it
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/fabio-vergani-b4682820a/">
                      <span className="icon style3 fa-linkedin"></span> Profilo
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>

              <span className="image">
                <img src={profilo_Fabio} alt="" />
              </span>
            </div>
          </section>
          <section className="main special">
            <Link to="/" className="button" style={{ margin: "0 auto" }}>
              Torna alla Home
            </Link>
          </section>
        </div>
      </Layout>
    );
  }
}

export default FacilityMan;
